import { createPlugin } from '@backstage/core-plugin-api';

import { createCardExtension } from '@backstage/plugin-home-react';

export const linksPlugin = createPlugin({
  id: 'links',
});

export const LinksPage = linksPlugin.provide(
  createCardExtension({
    name: 'Links',
    title: 'Links',
    components: () => import('./components/Links'),
  }),
);
