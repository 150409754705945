import { HomePageStarredEntities } from '@backstage/plugin-home';
import { LinksPage } from '@internal/backstage-plugin-links';
import { Header, Content, Page, InfoCard } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid } from '@material-ui/core';
import React from 'react';
import Tour from '../tour/Tour';

export const HomePage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Header title="Welcome to the intive Developer Platform!" />
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <InfoCard title="Take a tour!">
                  <Tour />
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={3} aria-label="StarredEntities">
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={3} aria-label="Links">
                <LinksPage title="Links" />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
